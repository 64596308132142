<template>
  <div>
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10 ">
              <span class="span marginRight10">优惠劵编号 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.code"
                  placeholder="优惠劵编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">使用状态 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="未使用"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="已使用"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="已过期"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">获取来源 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.getWay"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="后台发放"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="优惠券购买"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="好友裂变"
                  ></el-option>
                  <el-option
                    :value="4"
                    label="自行领取"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 "
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10"
              >重置</el-button>

            </div>

          </div>
          <div
            class="marginRight10 marginBottom10"
            style="text-align:right"
          >
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              @click="handleExport"
            >导出明细</el-button>
          </div>
        </div>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import { getCuponList } from "@/api/user/manage";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      searchData: {
        code: "",
        state: "",
        getWay: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "优惠券信息",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "优惠劵名称",
            prop: "name",
          },
          {
            label: "优惠劵编号",
            prop: "code",
          },
          {
            label: "使用状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "未使用"
                : row.state === 2
                ? "已使用"
                : row.state === 3
                ? "已过期"
                : row.state;
            },
          },
          {
            label: "获取来源",
            prop: "getWay",
            formatter: function (row, column, cellValue) {
              return row.getWay === 1
                ? "后台发放"
                : row.getWay === 2
                ? "优惠劵购买"
                : row.getWay === 3
                ? "好友裂变"
                : row.getWay === 4
                ? "自主领取"
                : row.getWay;
            },
          },
          {
            label: "购买金额（元）",
            prop: "price",
          },
          {
            label: "获取时间",
            prop: "createTime",
          },
        ],
      },
      form: {
        id: "",
        state: "",
        code: "",
        getWay: "",
        createTime: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (
      searchForm?.path === this.$route.path &&
      searchForm?.name === "createThree"
    ) {
      this.searchData = {
        state: searchForm.searchData?.state,
        code: searchForm.searchData?.code,
        getWay: searchForm.searchData?.getWay,
      };

      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        state: this.searchData.state,
        code: this.searchData.code,
        getWay: this.searchData.getWay,
      };

      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem(
        "searchForm",
        JSON.stringify({ ...searchForm, name: "createThree" })
      );

      getCuponList({
        id: this.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      const searchData = {
        state: this.searchData.state,
        code: this.searchData.code,
        getWay: this.searchData.getWay,
      };

      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem(
        "searchForm",
        JSON.stringify({ ...searchForm, name: "createThree" })
      );

      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        state: "",
        code: "",
        getWay: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/user/manage/details",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },

    // 导出
    handleExport() {
      const searchData = {
        id: this.id,
        state: this.searchData.state,
        code: this.searchData.code,
        getWay: this.searchData.getWay,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/user/manage/view/coupon/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
</style>
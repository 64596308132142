<template>
  <div>
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex align-items marginBottom20">
            <div class="flex align-items marginRight10 ">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>
          <div
            class="marginRight10 marginBottom10"
            style="text-align:right"
          >
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              @click="handleExport"
            >导出明细</el-button>
          </div>
        </div>
      </template>

      <template
        slot="code"
        slot-scope="{ row }"
      >
        <div v-if="row.reason.indexOf('订单完成') !== -1">{{row.code}}</div>
        <div v-else>-</div>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import { getIntegralList } from "@/api/user/manage";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      searchData: {
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "积分明细",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "单据编号",
            prop: "code",
          },
          {
            label: "变动原因",
            prop: "reason",
          },
          {
            label: "变更时间",
            prop: "createTime",
          },
          {
            label: "积分变化",
            prop: "integral",
            formatter: function (row, column, cellValue) {
              const type = row?.type == 1 ? "+" : row?.type == 2 ? "-" : "";
              return `${type}${cellValue}`;
            },
          },
        ],
      },
      form: {
        id: "",
        createTime: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (
      searchForm?.path === this.$route.path &&
      searchForm?.name === "createFive"
    ) {
      this.searchData = {};
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {};
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem(
        "searchForm",
        JSON.stringify({ ...searchForm, name: "createFive" })
      );

      getIntegralList({
        id: this.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/user/manage/details",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },

    // 导出
    handleExport() {
      const searchData = {
        id: this.id,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/user/manage/view/integral/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <div>
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex align-items marginBottom20">
            <div class="flex align-items marginRight10 ">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 ">
              <span class="span marginRight10">订单编号 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.code"
                  placeholder="订单编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 ">
              <span class="span marginRight10">订单状态 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="待支付"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="进行中"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="已完成"
                  ></el-option>
                  <el-option
                    :value="4"
                    label="售后中"
                  ></el-option>
                  <el-option
                    :value="5"
                    label="已关闭-正常关闭"
                  ></el-option>
                  <el-option
                    :value="6"
                    label="已关闭-拒绝售后"
                  ></el-option>
                  <el-option
                    :value="7"
                    label="已关闭-退款成功"
                  ></el-option>
                  <el-option
                    :value="8"
                    label="已关闭-换车成功"
                  ></el-option>
                  <el-option
                    :value="9"
                    label="已关闭-超时未支付"
                  ></el-option>
                  <el-option
                    :value="10"
                    label="已关闭-手动取消"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>
          <div
            class="marginRight10 marginBottom10"
            style="text-align:right"
          >
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              @click="handleExport"
            >导出明细</el-button>
          </div>
        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >详情</el-button>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import { getOrderList } from "@/api/user/manage";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      searchData: {
        code: "",
        state: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "订单明细",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "订单编号",
            prop: "code",
          },
          {
            label: "车型名称",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
          },
          {
            label: "套餐名称",
            prop: "packageName",
          },
          {
            label: "订单金额",
            prop: "payPrice",
            formatter: function (row, column, cellValue) {
              return `${cellValue}元`;
            },
          },
          {
            label: "下单时间",
            prop: "createTime",
          },
          {
            label: "订单状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "待支付"
                : row.state === 2
                ? "进行中"
                : row.state === 3
                ? "已完成"
                : row.state === 4
                ? "售后中"
                : row.state === 5
                ? "已关闭-正常关闭"
                : row.state === 6
                ? "已关闭-拒绝售后"
                : row.state === 7
                ? "已关闭-退款成功"
                : row.state === 8
                ? "已关闭-换车成功"
                : row.state === 9
                ? "已关闭-超时未支付"
                : row.state === 10
                ? "已关闭-手动取消"
                : row.state;
            },
          },
        ],
      },
      form: {
        id: "",
        state: "",
        code: "",
        createTime: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (
      searchForm?.path === this.$route.path &&
      searchForm?.name === "createTwo"
    ) {
      this.searchData = {
        state: searchForm.searchData?.state,
        code: searchForm.searchData?.code,
      };
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        state: this.searchData?.state,
        code: this.searchData?.code,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem(
        "searchForm",
        JSON.stringify({ ...searchForm, name: "createTwo" })
      );

      getOrderList({
        id: this.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        state: "",
        code: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/user/manage/details/detail",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },

    // 导出
    handleExport() {
      const searchData = {
        id: this.id,
        state: this.searchData.state,
        code: this.searchData.code,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/user/manage/view/order/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
</style>